<template>
    <div>
        <v-card flat>
            <v-card-title>
                Companies
                <v-spacer></v-spacer>
                <v-hover>
                    <template #default="hover">
                        <v-btn tile color="success" :class="hover.hover ? 'elevation-8' : 'elevation-2'"
                            @click="handleAddCompany">
                            <v-icon>{{ icons.mdiPlus }}</v-icon>Company</v-btn>
                    </template>
                </v-hover>
            </v-card-title>
            <v-card-text>
                <v-data-table hover :headers="companiesData.headers" :items="companiesData.items"
                    :loading="companiesData.loading" item-key="id" class="elevation-4" :hide-default-footer="true" :disable-pagination="true" 
                    :items-per-page="-1">
                    <template #item.actions="row">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon color="warning" v-bind="attrs" v-on="on" @click="handleEditCompany(row)">
                                    <v-icon>{{ icons.mdiPencil }}</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit company</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon color="error" v-bind="attrs" v-on="on" @click="handleDeleteCompany(row)">
                                    <v-icon>{{ icons.mdiDeleteVariant }}</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete company</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon color="info" v-bind="attrs" v-on="on" @click="handleGoToUsers(row)">
                                    <v-icon>{{ icons.mdiAccountGroup }}</v-icon>
                                </v-btn>
                            </template>
                            <span>View users</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        
        <v-card flat>
            <v-card-title>
                Taxonomy packages
                <v-spacer></v-spacer>
                <v-hover>
                    <template #default="hover">
                        <v-btn tile color="success" :class="hover.hover ? 'elevation-8' : 'elevation-2'"
                            @click="taxonomyForm.visible=true">
                            <v-icon>{{ icons.mdiPlus }}</v-icon>Taxonomy package</v-btn>
                    </template>
                </v-hover>
            </v-card-title>
            <v-card-text>
                <v-data-table
                hover class="elevation-4" item-key="full_path"
                :hide-default-footer="true"
                :disable-pagination="true"
                :items-per-page="-1"
                :headers="taxonomiesData.headers"
                :items="taxonomiesData.packages"
                :loading="taxonomiesData.busy"
                >
                    <template #item.actions="{item}">
                        <uv-button icon title="Delete package" @click="handleDeleteTaxonomy(item.name)" color="error">
                            <v-icon>{{ icons.mdiDeleteVariant }}</v-icon>
                        </uv-button>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        
        <v-dialog v-if="companyForm.visible" persistent dismissible :value="true" >
            <validation-observer tag="form" v-slot="{handleSubmit}">
                <form @submit.prevent="handleSubmit(submitCompany)">
                    <v-card>
                        <v-card-title>Enter company details</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <uv-text-field label="Name" v-model="companyForm.data.name" rules="required">
                                    </uv-text-field>
                                </v-col>
                                <v-col>
                                    <uv-text-field label="Uri" v-model="companyForm.data.uri" rules="required">
                                    </uv-text-field>
                                </v-col>
                                <v-col>
                                    <uv-autocomplete label="Entity Identifier" :loading="entityIdentifierData.busy"
                                        v-model="companyForm.data.entity_identifier" :items="entityIdentifierData.items"
                                        placeholder="Type to search for an entity identifier"
                                        @update:search-input="getEntityIdentifiers" rules="required">
                                        <template #item="{item , on, attrs}">
                                            <v-list-item ripple v-on="on" v-bind="attrs" dense>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{item.value}}</v-list-item-title>
                                                    <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <template #selection="{item}">
                                            <span>{{item.value}}</span>
                                        </template>
                                        <template #progress>
                                            <v-progress-circular indeterminate :size="20" color="primary">
                                            </v-progress-circular>
                                        </template>
                                    </uv-autocomplete>
                                </v-col>
                                <v-col>
                                    <uv-text-field label="Entity scheme" v-model="companyForm.data.entity_scheme"
                                        rules="required">
                                    </uv-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" text @click="companyForm.visible = false">Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="success" :loading="companyForm.busy" text type="submit">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </form>
            </validation-observer>
        </v-dialog>

        <v-dialog v-if="taxonomyForm.visible" :value="true" persistent width="50%">
            <validation-observer v-slot="{handleSubmit, invalid}" slim>
            <v-card>
                <v-card-title>Upload a taxonomy package</v-card-title>
                <v-card-text>
                    <uv-file-input
                        v-model="taxonomyForm.data.file"
                        show-size show-drop
                        accept="application/zip"
                        label="Select a file"
                        rules="required"
                    ></uv-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary" text @click="taxonomyForm.visible = false">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :disabled="invalid" :loading="taxonomyForm.busy" text @click="handleSubmit(submitTaxonomyPackage)">Upload</v-btn>
                </v-card-actions>
            </v-card>
            </validation-observer>
        </v-dialog>
        <uv-confirm-dialog ref="deleteConfirmation">
            {{deleteConfirmationMessage}}
        </uv-confirm-dialog>
    </div>
</template>
<script>
import { mdiPlus, mdiDeleteVariant, mdiPencil, mdiAccountGroup } from "@mdi/js";
import uvConfirmDialog from "@/components/ConfirmDialog";
import _ from "lodash";
import axios from "axios";

export default {
    name: "Admin",
    components: {
        uvConfirmDialog
    },
    created() {
        this.resetFormData();
        this.companiesData.busy = true;
        this.$apiHttp({
            url: "/companies"
        }).then((response) => {
            this.companiesData.items = response.data;
        }).finally(() => {
            this.companiesData.busy = false;
        });

        this.taxonomiesData.busy = true;
        this.$apiHttp({
            url:"/taxonomy/packages"
        }).then((res)=>{
            this.taxonomiesData.packages = res.data.packages;
        }).finally(()=>{
            this.taxonomiesData.busy = false;
        });

        this.getEntityIdentifiers = _.debounce((search) => {
            let params = {}
            if (this.entityIdentifierData.busy) {
                this.entityIdentifierData.abortController?.abort();
            }
            if (search !== null && search !== undefined && search !== "") {
                params['filter[entity.names]'] = search;
                params['page[size]'] = 200; //max page size for api
                this.entityIdentifierData.busy = true;
                this.entityIdentifierData.abortController = new AbortController();
                axios.get('https://api.gleif.org/api/v1/lei-records', {
                    params
                }).then((response) => {
                    let searchResults = response.data.data.map(item => ({ text: item.attributes.entity.legalName.name, value: item.attributes.lei }));
                    let difference = searchResults.filter(x => !this.entityIdentifierData.items.includes(x));
                    this.entityIdentifierData.items.push(...difference);
                }).catch((error) => {
                    console.error(error);
                    this.$store.commit("setGlobalMessage", {
                        severity: "error",
                        message: "Error getting entity identifiers"
                    });
                }).finally(() => {
                    this.entityIdentifierData.busy = false;
                })
            }
        }, 500)
    },
    beforeDestroy() {
        if (this.entityIdentifierData.busy) this.entityIdentifierData.abortController.abort();
    },
    data() {
        return {
            companyForm: {
                visible: false,
                busy: false,
                data: null,
                roles: [
                    {
                        text: "user",
                        value: 2
                    },
                    {
                        text: "admin",
                        value: 1,
                        disabled: true,
                    }
                ],
            },
            defaultCompanyData: {
                id: null,
                name: null,
                uri: null,
                entity_identifier: null,
                entity_scheme: "http://standards.iso.org/iso/17442",
            },
            icons: {
                mdiPlus: mdiPlus,
                mdiDeleteVariant: mdiDeleteVariant,
                mdiPencil: mdiPencil,
                mdiAccountGroup
            },
            companiesData: {
                busy: false,
                headers: [
                    {
                        value: "id",
                        text: "Id",
                        align: "right"
                    },
                    {
                        value: "name",
                        text: "Name",
                        align: "left"
                    },
                    {
                        value: "uri",
                        text: "Uri",
                        align: "left"
                    },
                    {
                        value: "entity_identifier",
                        text: "Entity identifier",
                        align: "left"
                    },
                    {
                        value: "entity_scheme",
                        text: "Entity scheme",
                        align: "left"
                    },
                    {
                        value: "actions",
                        text: "",
                        align: "right"
                    }
                ],
                items: []
            },
            entityIdentifierData: {
                items: [],
                busy: false,
                abortController: new AbortController()
            },
            taxonomiesData:{
                busy: false,
                headers: [
                    {
                        value: "name",
                        text: "Name"
                    },
                    {
                        value: "full_path",
                        text: "URI"
                    },
                    {
                        value: "actions",
                        text: "",
                        align: "right"
                    }
                ],
                packages: []
            },
            taxonomyForm:{
                visible:false,
                busy:false,
                data:{}
            },
            deleteConfirmationMessage: "",
        }
    },
    methods: {
        resetFormData() {
            this.companyForm.data = JSON.parse(JSON.stringify(this.defaultCompanyData));
        },
        handleDeleteCompany: async function (row) {
            let idx = this.companiesData.items.indexOf(row.item);
            this.deleteConfirmationMessage = "Are you sure you want to delete this company?"
            let del = await this.$refs.deleteConfirmation.open();
            if (del) {
                this.$apiHttp({
                    url: `/companies/${row.item.id}`,
                    method: "DELETE",
                })
                    .then(() => {
                        this.companiesData.items.splice(idx, 1);
                    });
            }
            this.deleteConfirmationMessage = "";
        },
        getEntityByIdentifier(identifier) {
            if (this.entityIdentifierData.busy) {
                this.entityIdentifierData.abortController?.abort();
            }
            this.entityIdentifierData.busy = true;
            this.entityIdentifierData.abortController = new AbortController();
            
            axios.get(`https://api.gleif.org/api/v1/lei-records/${identifier}`)
                .then((response) => {
                    this.entityIdentifierData.items.push({ text: response.data.data.attributes.entity.legalName.name, value: response.data.data.attributes.lei});
                }).catch((error) => {
                    console.error(error);
                    this.$store.commit("setGlobalMessage", {
                        severity: "error",
                        message: "Error getting entity identifier"
                    });
                }).finally(() => {
                    this.entityIdentifierData.busy = false;
                })
        },
        handleEditCompany(row) {
            if (row) {
                this.companyForm.data = Object.assign({}, row.item);
                this.getEntityByIdentifier(row.item.entity_identifier);
            }
            this.companyForm.visible = true;
        },
        handleAddCompany(row) {
            if (row) {
                this.companyForm.data = Object.assign({}, this.defaultCompanyData);
            }
            this.companyForm.visible = true;
        },
        submitCompany() {
            this.companyForm.busy = true;
            this.$apiHttp({
                url: "/companies",
                method: 'post',
                data: this.companyForm.data
            })
                .then((response) => {
                    if (this.companyForm.data.id !== null && this.companyForm.data.id !== undefined) {
                        let oldCompany = this.companiesData.items.find((item) => item.id === response.data.data.id)
                        let idx = this.companiesData.items.indexOf(oldCompany);
                        this.companiesData.items.splice(idx, 1, response.data.data);
                    } else {
                        this.companiesData.items.push(response.data.data);
                    }
                    this.companyForm.visible = false;
                })
                .finally(() => {
                    this.companyForm.busy = false;
                });
        },
        handleGoToUsers(row) {
            this.$router.push({ name: "Users", params: { companyId: row.item.id } });
        },
        submitTaxonomyPackage(){
            this.taxonomyForm.busy = true;
            let formData = new FormData();
            formData.append('file', this.taxonomyForm.data.file);
            this.$apiHttp({
                url: "/taxonomy/package",
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    this.taxonomyForm.visible = false;
                    this.taxonomiesData.packages = response.data.data.packages;
                })
                .finally(() => {
                    this.taxonomyForm.busy = false;
                });
        },
        async handleDeleteTaxonomy(name){
            this.deleteConfirmationMessage = "Are you sure you want to delete this taxonomy package?"
            const answer = await this.$refs.deleteConfirmation.open()
            if (answer){
                this.taxonomiesData.busy = true
                this.$apiHttp({
                    url: `/taxonomy/package/${name}`,
                    method: 'delete'
                })
                    .then((res) => {
                        this.taxonomiesData.packages = res.data.data.packages
                    })
                    .finally(() => {
                        this.taxonomiesData.busy = false
                        this.deleteConfirmationMessage = ""
                    });
            }
        }
    },
    watch:{
        'taxonomyForm.visible': function(val){
            if(!val){
                this.taxonomyForm.data = {};
            }
        }
    }
}
</script>