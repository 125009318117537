<template>
    <div class="uv-page">

        <v-card flat>
            <v-card-title>
                <span>{{ currentCompany?.name }}</span>
                <v-spacer></v-spacer>
                <v-hover>
                    <template #default="hover">
                        <v-btn tile color="success" :class="hover.hover ? 'elevation-2' : 'elevation-8'"
                            @click="editUser(null)">
                            <v-icon>{{ icons.mdiPlus }}</v-icon> User
                        </v-btn>
                    </template>
                </v-hover>
            </v-card-title>
            <v-card-text>
                <v-data-table hover :headers="users.headers" :items="users.items" :loading="users.loading" item-key="id"
                    class="elevation-4" :hide-default-footer="true">
                    <template #item.actions="row">
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon color="warning" v-bind="attrs" v-on="on" @click="editUser(row)">
                                    <v-icon>{{ icons.mdiPencil }}</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit user</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteUser(row)">
                                    <v-icon>{{ icons.mdiDeleteVariant }}</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete user</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="addUserForm.visible" persistent dismissible width="50%">

            <validation-observer ref="userForm" v-slot="{ handleSubmit }" >
                <form @submit.prevent="handleSubmit(submitUser)">
                    <v-card>
                        <v-card-title>Enter user details</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <uv-text-field label="E-mail" v-model="addUserForm.data.email" rules="required|email">
                                    </uv-text-field>
                                </v-col>
                                <v-col v-if="addUserForm.rowIndex < 0">
                                    <uv-text-field label="Password" type="password" rules="required" v-model="addUserForm.data.password">
                                    </uv-text-field>
                                </v-col>
                                <v-col v-if="addUserForm.rowIndex < 0">
                                    <uv-text-field label="Repeat password" type="password" rules="required" name="confirmpass"
                                        v-model="addUserForm.data.repeatPassword">
                                    </uv-text-field>
                                </v-col>
                                <v-col>
                                    <uv-select :items="enumsTextItems.XbrlUserRole" v-model="addUserForm.data.role_id" rules="required"
                                        label="Role"></uv-select>
                                </v-col>
                                <v-col v-if="addUserForm.rowIndex >= 0">
                                    <uv-select :items="companyData.items" item-value="id" item-text="name" v-model="addUserForm.data.company_id" rules="required"
                                        label="Company"></uv-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" text @click="closeUserForm">Cancel</v-btn>
                            <v-btn color="success" :loading="addUserForm.busy" text type="submit">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </form>
            </validation-observer>



        </v-dialog>
        <uv-confirm-dialog ref="deleteConfirmation">
            Are you sure you want to delete this user?
        </uv-confirm-dialog>
    </div>

</template>
<script>
let vm;
import { mdiPlus, mdiDeleteVariant, mdiPencil } from "@mdi/js";
import uvConfirmDialog from "@/components/ConfirmDialog";
import { enumsMixin } from "@/plugins/mixins";

export default {
    name: "Users",
    components: {
        uvConfirmDialog
    },
    mixins: [enumsMixin],
    props: ["companyId"],
    created() {
        vm = this;
        this.companyData.busy = true;
        this.$apiHttp({
            url: "/companies",
        })
            .then((response) => {
                this.companyData.items = response.data;
            }).finally(() => {
                this.companyData.busy = false;
            });
    },
    mounted() {
        let params = {};
        if (this.companyId !== null && this.companyId !== undefined) {
            params.company_id = this.companyId;
        }
        this.$apiHttp({
            url: "/users",
            params
        })
            .then(function (response) {
                vm.users.items = response.data;
                vm.users.loading = false;
            });
    },
    data() {
        return {
            enumNames: ["XbrlUserRole"],
            companyData: {
                busy: false,
                items: []
            },
            addUserForm: {
                visible: false,
                busy: false,
                rowIndex: -1,
                data: {
                    email: "",
                    role_id: "",
                    id: "",
                    password: "",
                    repeatPassword: "",
                    company_id: ""
                },
            },
            icons: {
                mdiPlus: mdiPlus,
                mdiDeleteVariant: mdiDeleteVariant,
                mdiPencil: mdiPencil
            },
            users: {
                loading: true,
                headers: [
                    {
                        value: "id",
                        text: "Id"
                    },
                    {
                        value: "email",
                        text: "Email"
                    },
                    {
                        value: "role_id",
                        text: "Role"
                    },
                    {
                        value: "actions",
                        text: "",
                        align: "right"
                    }
                ],
                items: []
            }
        }
    },
    computed: {
        currentCompany() {
            if (this.companyId !== null && this.companyId !== undefined){
                return this.companyData.items.find((item) => item.id === parseInt(this.companyId));
            }
            return {};
        }
    },
    methods: {
        deleteUser: async function (row) {
            let idx = this.users.items.indexOf(row.item);
            let del = await this.$refs.deleteConfirmation.open();
            if (del) {
                this.$apiHttp({
                    url: `/users/${row.item.id}`,
                    method: "DELETE",
                })
                    .then(function () {
                        vm.users.items.splice(idx, 1);
                    });
            }
        },
        editUser: function (row) {
            this.addUserForm.data.company_id = this.companyId;
            if (row) {
                this.addUserForm.data = Object.assign({}, row.item); //copiem
                this.addUserForm.rowIndex = this.users.items.indexOf(row.item);
            }
            this.addUserForm.visible = true;
        },
        closeUserForm: function () {
            this.addUserForm.data = {};
            this.addUserForm.rowIndex = -1;
            this.$refs.userForm.reset();
            this.addUserForm.visible = false;
        },
        submitUser: function () {
            if (this.addUserForm.rowIndex < 0 && this.addUserForm.data.password !== this.addUserForm.data.repeatPassword){
                this.$refs.userForm.setErrors({
                    confirmpass: ["Passwords don't match"]
                })
            }else{
                this.addUserForm.busy = true;
                this.$apiHttp({
                    url: "/users",
                    method: this.addUserForm.rowIndex < 0 ? 'post' : 'put',
                    data: this.addUserForm.data
                })
                    .then(function (response) {
                        if (vm.addUserForm.rowIndex < 0) { //adaugat
                            vm.users.items.push(response.data.data);
                        } else {
                            let oldUser = vm.users.items[vm.addUserForm.rowIndex];
                            if (oldUser?.company_id !== response.data.data.company_id) {
                                vm.users.items.splice(vm.addUserForm.rowIndex, 1);
                            }else{
                                Object.assign(oldUser, response.data.data);
                            }
                        }
                        vm.closeUserForm();
                    })
                    .finally(function () {
                        vm.addUserForm.busy = false
                    });
            }
        }
    }
}
</script>